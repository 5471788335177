<template>
  <span
    :class="className"
    :style="!size ? bgStyle : ''"
    role="switch"
    tabindex="0"
    :aria-checked="value ? 'true' : 'false'"
    :aria-readonly="disabled ? 'true' : 'false'"
    @click="onClick"
    @keyup.enter.prevent="onClick"
    @keyup.space.prevent="onClick"
  >
    <span aria-hidden="true" :style="!size ? dotStyle: ''" class="dot">
        <span v-show="value" :style="!size ? textStyle : ''" class="text">
            <template v-if="checkedText">{{ checkedText }}</template>
            <template v-else>
                <svg-icon name="ib-switch-on" class-name="margin-r-2"></svg-icon>
            </template>
        </span>
        <span v-show="!value" :style="!size ? textStyle : ''" class="text">
            <template v-if="uncheckedText">{{ uncheckedText }}</template>
            <template v-else>
                <svg-icon name="ib-switch-off" class-name="margin-l-2"></svg-icon>
            </template>
        </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'NextSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    checkedText: {
      type: String,
      default: null,
    },
    uncheckedText: {
      type: String,
      default: null,
    },
    size: {
        type: String,
        default: '',
    },
    width: {
      type: [Number, String],
      default: 38,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    uncheckedBg: {
      type: String,
      default: '', //#939393
    },
    checkedBg: {
      type: String,
      default: '', //#5850ec
    },
    dotColor: {
      type: String,
      default: '#fff',
    },
    fontSize: {
      type: [Number, String],
      default: '12',
    },
    checkedColor: {
      type: String,
      default: '#fff',
    },
    uncheckedColor: {
      type: String,
      default: '#fff',
    },
    fontWeight: {
      type: [Number, String],
      default: 'normal',
    },
  },
  computed: {
    className(){
        let size = this.size;
        return {
            'next-switch': true,
            'ns-on': this.value,
            [size]: this.size,

        }
    },
    bgStyle() {
      const styles = {
        width: `${this.width}px`,
        height: `${this.height}px`,
        backgroundColor: this.value ? (this.checkedBg ? this.checkedBg : undefined) : (this.uncheckedBg ? this.uncheckedBg: undefined),
        opacity: this.disabled ? '0.3' : '1',
        cursor: !this.disabled ? 'pointer' : 'not-allowed',
      };

      return styles;
    },
    dotStyle() {
      const styles = {
        backgroundColor: this.dotColor,
        width: `${this.height - 4}px`,
        height: `${this.height - 4}px`,
        'min-width': `${this.height - 4}px`,
        'min-height': `${this.height - 4}px`,
        'margin-left': this.value ? `${this.width - (this.height - 2)}px` : '2px',
      };

      if ((!this.value && this.reverse) || (this.value && !this.reverse)) {
        styles.marginLeft = `${this.width - (this.height - 2)}px`;
      } else if ((this.value && this.reverse) || (!this.value && !this.reverse)) {
        styles.marginLeft = '2px';
      }

      return styles;
    },
    textStyle() {
      const styles = {
        'font-weight': this.fontWeight,
        'font-size': `${this.fontSize}px`,
        color: this.value && !this.disabled ? this.checkedColor : this.uncheckedColor,
        right: this.value ? `${this.height - 3}px` : 'auto',
        left: this.value ? 'auto' : `${this.height - 3}px`,
      };

      if (!this.value && this.reverse) {
        styles.right = `${this.height - 3}px`;
        styles.left = 'auto';
      } else if (this.value && this.reverse) {
        styles.left = `${this.height - 3}px`;
        styles.right = 'auto';
      }

      return styles;
    },
  },
  methods: {
    onClick(){
        if(!this.disabled){
            this.$emit('input', !this.value);
            this.$emit('change', !this.value);
        }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.next-switch {
    width: 38px;
    height: 20px;
    display: flex;
    // display: inline-block;
    align-items: center;
    border-radius: 9999px;
    cursor: pointer;
    overflow: hidden;
    background-color: #c4c4c4;
    transition: background-color ease 0.2s, width ease 0.2s, height ease 0.2s;
    .dot {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        min-width: 16px;
        min-height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 9999px;
        background-color: #ffffff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: margin ease 0.2s;
    }
    .text {
        position: absolute;
        font-family: inherit;
        user-select: none;
        white-space: nowrap;
        font-size: 12px;
        font-weight: normal;
        color: #fff;
        right: auto;
        left: 17px;
    }
    &.ns-on {
        background-color: #00B670;
        .dot {
            margin-left: 20px;
        }
        .text {
            left: auto;
            right: 17px;    
        }
        &.medium {
            .text {
                left: auto;
                right: 15px;
            }
        }
        &.small {
            .text {
                left: auto;
                right: 13px;
            }
        }
        &.mini {
            .text {
                left: auto;
                right: 11px;
            }
        }
    }
    &.medium {
        width: 36px;
        height: 18px;
        .dot {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
        }
        .text {
            left: 15px;
        }
    }
    &.small {
        width: 34px;
        height: 16px;
        .dot {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
        }
        .text {
            left: 13px;
        }
    }
    &.mini {
        width: 32px;
        height: 14px;
        .dot {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
        }
        .text {
            left: 11px;
        }
    }
    
}

@media all and (-ms-high-contrast: none) {
  .next-switch .text {
    /* IE11 fix */
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (prefers-reduced-motion) {
  /* disable animations if user have a reduced motion setting */
  .next-switch,
  .next-switch *,
  .next-switch *::before,
  .next-switch *::after {
    animation: none !important;
    transition: none !important;
    transition-duration: none !important;
  }
}
</style>
