<template>
    <!-- <div class="term-setting-component">

    </div> -->
    <el-dialog :title="$t('engine.config_page_term_title')" :visible.sync="show" destroy-on-close width="780px" top="20px" @close="handleDialogClose">
        <div class="text-center fonts-12 margin-b-20">{{$t('engine.config_page_term_descoption')}}</div>
        <el-table :data="list" ref="tableList" @row-click="handleTableRowClick" class="term-setting-table" style="width: 100%">
          <el-table-column :label="$t('engine.config_page_term_table_label_1')" type="index" :index="index=>{return index===0?'-':index}" width="50"></el-table-column>
          <el-table-column :label="$t('engine.config_page_term_table_label_2', {name: LANGUAGE_DICT[lang.source].name})" column-key="source">
            <template slot-scope="scope">
                <div :lang="lang.source">
                    <template v-if="scope.row.edit === true">
                        <el-input size="small" :disabled="scope.row.loading" maxlength="500" show-word-limit v-model="scope.row.sourceText" :placeholder="$t('engine.config_page_term_table_input_1')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                    </template>
                    <template v-else>
                        <template v-if="scope.row.termId">{{scope.row.sourceText}}</template>
                        <template v-else>
                            <el-input size="small" :disabled="newLoading" maxlength="500" show-word-limit v-model="newSourceValue" :placeholder="$t('engine.config_page_term_table_input_1')" @keydown.native.enter="saveAddEvent"></el-input>
                        </template>
                    </template>
                </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('engine.config_page_term_table_label_3', {name: LANGUAGE_DICT[lang.target].name})" column-key="target">
            <template slot-scope="scope">
                <div :lang="lang.target">
                    <template v-if="scope.row.edit === true">
                        <el-input size="small" :disabled="scope.row.loading" maxlength="500" show-word-limit v-model="scope.row.targetText" :placeholder="$t('engine.config_page_term_table_input_2')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                    </template>
                    <template v-else>
                        <template v-if="scope.row.termId">{{scope.row.targetText}}</template>
                        <template v-else>
                            <el-input size="small" :disabled="newLoading" maxlength="500" show-word-limit v-model="newTargetValue" :placeholder="$t('engine.config_page_term_table_input_2')" @keydown.native.enter="saveAddEvent"></el-input>
                        </template>
                    </template>
                </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('engine.config_page_term_table_label_4')" width="70">
            <template slot-scope="scope">
                <template v-if="scope.row.edit === true">
                    <el-tooltip :content="$t('engine.config_page_term_table_button_1')" placement="top">
                        <el-button type="text" :disabled="scope.row.loading" @click="saveEditEvent(scope)"><svg-icon name="ib-check-o"></svg-icon></el-button>
                    </el-tooltip>
                    <el-tooltip :content="$t('engine.config_page_term_table_button_2')" placement="top">
                        <el-button type="text" :disabled="scope.row.loading" @click="deleteBtnEvent(scope)"><svg-icon name="ib-trash-o"></svg-icon></el-button>
                    </el-tooltip>
                </template>
                <template v-else>
                    <template v-if="scope.row.termId">
                        <el-tooltip :content="$t('engine.config_page_term_table_button_3')" placement="top">
                            <el-button type="text" :disabled="scope.row.loading" @click="editBtnEvent(scope)"><svg-icon name="ib-edit"></svg-icon></el-button>
                        </el-tooltip>
                        <el-tooltip :content="$t('engine.config_page_term_table_button_2')" placement="top">
                            <el-button type="text" :disabled="scope.row.loading" @click="deleteBtnEvent(scope)"><svg-icon name="ib-trash-o"></svg-icon></el-button>
                        </el-tooltip>
                    </template>
                    <template v-else>
                        <el-tooltip :content="$t('engine.config_page_term_table_button_1')" placement="top">
                            <el-button type="text" :disabled="newLoading" @click="saveAddEvent"><svg-icon name="ib-check-o"></svg-icon></el-button>
                        </el-tooltip>
                    </template>
                </template>
            </template>
          </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import config from '@/utils/config';
import { myEngineApi } from '@/utils/api';
export default {
    name: 'TermSetting',
    props: {
        lang: {
            type: Object,
            default: {
                source: 'zh',
                target: 'en',
            }
        }
    },
    data () {
        return {
            config,
            show: false,
            list: [],
            newSourceValue: '',
            newTargetValue: '',
            newRemarkValue: '',
            newLoading: false,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods:{
        setShow(is){
            this.show = is;
            if(is){
                this.initPage();
            }
        },
        handleDialogClose(){
            
        },
        initPage(){
            let url = `${myEngineApi.queryMyTerms}?sourceLanguage=${this.lang.source}&targetLanguage=${this.lang.target}`;
            this.$ajax.get(url).then(res => {
                if(res.status == 200){
                    this.list = [].concat(
                        [{termId:null, source:'', target:'', description:'', edit:false, loading: false}],
                        res.data.terms.map(item => {
                            return {
                                ...item,
                                edit: false,
                                loading: false,
                            }
                        })
                    );
                }
            });
        },
        handleTableRowClick(row, column, event){
            if(['source', 'target'].indexOf(column.columnKey) > -1){
                if (row.termId && !row.edit) {
                    let index = -1;
                    for (let i = 0; i < this.list.length; i++) {
                        const element = this.list[i];
                        if (element.termId === row.termId) {
                            index = i;
                            break;
                        }
                    }
                    if (index > 0) {
                        this.list.splice(index,1,{...this.list[index],edit:true});
                        setTimeout(() => {
                            $(event.target).find('input').focus();
                        }, 100);
                    }
                }
            }
        },
        saveAddEvent(){
            if(!this.newSourceValue){
                this.$message.error(this.$t('engine.config_page_term_verification_message_1'));
                return;
            }
            if(!this.newTargetValue){
                this.$message.error(this.$t('engine.config_page_term_verification_message_2'));
                return;
            }
            let url = myEngineApi.createTerm;
            let postData={
                sourceLanguage: this.lang.source,
                targetLanguage: this.lang.target,
                sourceText:this.newSourceValue,
                targetText:this.newTargetValue,
                description:this.newRemarkValue,
            }
            this.newLoading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('engine.config_page_term_message_1'));
                    this.newSourceValue = '';
                    this.newTargetValue = '';
                    this.newRemarkValue = '';
                    this.initPage();
                }
            }).finally(() => {
                this.newLoading = false;
            });
        },
        editBtnEvent(scope){
            this.list.splice(scope.$index,1,{...this.list[scope.$index],edit:true});
        },
        saveEditEvent(scope){
            let row = scope.row;
            if(!row.sourceText){
                this.$message.error(this.$t('engine.config_page_term_verification_message_1'));
                return;
            }
            if(!row.targetText){
                this.$message.error(this.$t('engine.config_page_term_verification_message_2'));
                return;
            }
            let url = myEngineApi.updateTerm;
            let postData = {
                sourceText: row.sourceText,
                targetText: row.targetText,
                description: row.description,
                termId: row.termId
            }
            this.list.splice(scope.$index,1,{...this.list[scope.$index],loading:true});
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('engine.config_page_term_message_1'));
                    this.list.splice(scope.$index, 1, {...this.list[scope.$index], edit: false});
                }
            }).finally(() => {
                this.list.splice(scope.$index, 1, {...this.list[scope.$index], loading: false});
            })
        },
        deleteBtnEvent(scope){
            let url = myEngineApi.deleteTerm;
            let termIds=[scope.row.termId];
            this.list.splice(scope.$index,1,{...this.list[scope.$index],loading:true});
            this.$ajax.post(url,{termIds}).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('engine.config_page_term_message_2'));
                    this.initPage();
                }
            }).finally(() =>{
                this.list.splice(scope.$index, 1, {...this.list[scope.$index], loading: false});
            })
        },
    },
    mounted(){
        
    },   
}
</script>
<style lang="scss" scoped>
.term-setting-table{
    ::v-deep .el-button--text{
        padding: 0;
        font-size: 17px;
    }
}
</style>
